<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo -->
      <b-link class="brand-logo">
        <b-img
          v-if="mode === 'dark'"
          :src="appDarkLogoImage"
          width="150"
          :alt="`${appName} logo`"
        />
        <b-img
          v-else
          :src="appLogoImage"
          width="150"
          :alt="`${appName} logo`"
        />
      </b-link>
      <!-- /Brand logo -->

      <!-- Left Panel -->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            class="sidebar-image"
            alt="Login"
          />
        </div>
      </b-col>
      <!-- /Left Panel -->

      <!-- Login Panel -->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Sign In
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account.
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{ invalid, dirty }"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email input -->
              <b-form-group
                label="Email"
                label-for="email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                  vid="email"
                >
                  <b-form-input
                    id="email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="email"
                    placeholder="john@example.com"
                    autocomplete="username"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password input -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="password">Password</label>
                  <b-link :to="{ name: 'auth-forgot-password' }">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                  vid="password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="password"
                      placeholder="············"
                      autocomplete="current-password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- remember me checkbox (TODO) -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="remember-me"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid || !dirty"
              >
                Sign in
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>New to {{ appName }}? </span>
            <b-link :to="{ name: 'auth-register' }">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text>

          <!-- <b-link
            v-if="redirectUrl"
            :href="redirectUrl"
          >
            <b-button
              id="login-redirect-btn"
              variant="primary"
              class="mb-2"
            >
              Continue on Google
            </b-button>
          </b-link> -->

          <!-- <b-button
            id="google-login-btn"
            v-google-identity-login-btn="{ clientId, locale:'en' }"
          >
            Continue on Google
          </b-button> -->

          <!-- <b-button
            v-if="developmentEnv"
            @click="loginDev"
          >
            Continue on Development
          </b-button> -->
        </b-col>
      </b-col>
      <!-- /Login Panel -->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { $themeConfig } from '@themeConfig'
import {
  BRow,
  BCol,
  BLink,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BButton,
} from 'bootstrap-vue'
// import GoogleSignInButton from 'vue-google-identity-login-btn'

import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'
// import { adminAbility } from '@/libs/acl/config'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    // GoogleSignInButton,
  },
  setup() {
    // App Name
    const { appName, appLogoImage, appDarkLogoImage } = $themeConfig.app

    return {
      // App Name
      appName,
      appLogoImage,
      appDarkLogoImage,
    }
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/bubbles.png'),
      /* developmentEnv: false, */

      // google auth
      /* redirectUrl: null,
      clientId: '996092794038-1916us718td4p06bnr8g4a86i296e845.apps.googleusercontent.com', */

      // validation rulesimport store from '@/store/index'
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/bubbles.png')
        return this.sideImg
      }
      return this.sideImg
    },
    mode() {
      return this.$store.state.appConfig.layout.skin
    },
  },
  created() {
    /* if (process.env.NODE_ENV === 'development') {
      this.developmentEnv = true
    } */
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          // reset form validation state
          // so we can track if form data is dirty
          this.$refs.loginForm.reset()
          useJwt
            .login({
              email: this.userEmail,
              password: this.password,
            })
            .then(response => {
              console.log('login', response.data.data)
              const loginData = response.data.data
              this.loginUser(loginData)
            })
            .catch(error => {
              // highlight Email field with the received error
              if (error.response.data.error === 'Unauthorized') {
                this.$refs.loginForm.setErrors({ password: 'Incorrect email/password' })
              } else {
                this.$refs.loginForm.setErrors({
                  password: error.response.data.details || error.response.data.error,
                })
              }
            })
        }
      })
    },

    loginUser(authTokenData) {
      // login user
      const loginToken = authTokenData.token

      useJwt.setToken(loginToken)
      useJwt.setRefreshToken(loginToken) // TODO

      // get current user data
      this.$store.dispatch('users/fetchCurrentUser')
        .then(userData => {
          // set user access ability
          this.$ability.update(userData.ability)

          // if account isn't verified yet, send to account verification page
          const nextRoute = userData.email_verified ? '/' : { name: 'auth-verify-account' }

          // send user to either dashboard or account verification
          this.$router.replace(nextRoute).then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Welcome ${userData.fullName}`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'You have successfully logged in.',
              },
            })
          })
        })
        .catch(error => {
          console.log('error', error)
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.sidebar-image { max-width: 600px; }
</style>
